import './style.css'
import './main.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage"
import { getFirestore, collection, setDoc, documentId, doc, updateDoc } from "firebase/firestore"
// gsap
import { gsap } from 'gsap'

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyDLBF1kpPqGPOlC2DXpOJcYJ6cffELo7Tw",
    authDomain: "paulnic-b37f7.firebaseapp.com",
    projectId: "paulnic-b37f7",
    storageBucket: "paulnic-b37f7.appspot.com",
    messagingSenderId: "174875256446",
    appId: "1:174875256446:web:d762322061f54498b91a2f"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get aa reference to the service
const auth = getAuth(app);

const db = getFirestore(app);

const storage = getStorage(app);

console.log('Connected');

const reference = ref(storage, 'tutorial')

const scaleDownFactor = 1 / 200
const textureLoader = new THREE.TextureLoader()

function toggleSignIn() {
    if (auth.currentUser) {
        auth.signOut();
    } else {
        var email = document.getElementById('email').value
        if (email.toString().includes('@') == false) {
            email = email + '@resus.net.au'
        }
        var password = document.getElementById('password').value;
        if (email.length < 4) {
            alert('Please enter an email address.');
            return;
        }
        if (password.length < 4) {
            alert('Please enter a password.');
            return;
        }

        // Sign in with email and pass.
        signInWithEmailAndPassword(auth, email, password).catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
                alert('Wrong password.');
            } else {
                alert(errorMessage);
            }
            document.getElementById('quickstart-sign-in').disabled = false;
        });
    }
    document.getElementById('quickstart-sign-in').disabled = true;

}


/**
 * Handles the sign up button press.
 */
function handleSignUp() {
    var email = document.getElementById('email').value;
    var password = document.getElementById('password').value;
    if (email.length < 4) {
        alert('Please enter an email address.');
        return;
    }
    if (password.length < 4) {
        alert('Please enter a password.');
        return;
    }
    // Create user with email and pass.
    createUserWithEmailAndPassword(auth, email, password).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/weak-password') {
            alert('The password is too weak.');
        } else {
            alert(errorMessage);
        }
    });
}

/**
 * Sends an email verification to the user.
 */
function sendEmailVerification() {
    auth.currentUser.sendEmailVerification().then(function () {
        // Email Verification sent!
        alert('Email Verification Sent!');
    });
}

function sendPasswordReset() {
    var email = document.getElementById('email').value;
    auth.sendPasswordResetEmail(email).then(function () {
        // Password Reset Email Sent!
        alert('Password Reset Email Sent!');
    }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/invalid-email') {
            alert(errorMessage);
        } else if (errorCode == 'auth/user-not-found') {
            alert(errorMessage);
        }
    });
}

auth.signOut();

/**
 * initApp handles setting up UI eveant listeners and registering Firebase auth listeners:
 *  - auth.onAuthStateChaanged: This listener is called when the user is signed in or
 *    out, and that is where we update the UI.
 */
function initApp() {

    // Listening for auth state changes.
    auth.onAuthStateChanged(function (user) {
        document.getElementById('quickstart-verify-email').disabled = true;
        if (user) {
            // User is signed in.
            var displayName = user.displayName;
            var email = user.email;
            var emailVerified = user.emailVerified;
            var photoURL = user.photoURL;
            var isAnonymous = user.isAnonymous;
            var uid = user.uid;
            var providerData = user.providerData;
            document.getElementById('quickstart-sign-in-status').textContent = 'Signed in';
            document.getElementById('quickstart-sign-in').textContent = 'Sign out';
            if (!emailVerified) {
                document.getElementById('quickstart-verify-email').disabled = false;
            }
            startTutorial()

        } else {
            // User is signed out.
            document.getElementById('quickstart-sign-in-status').textContent = 'Signed out';
            document.getElementById('quickstart-sign-in').textContent = 'Sign in';
        }
        document.getElementById('quickstart-sign-in').disabled = false;
    });

    document.getElementById('quickstart-sign-in').addEventListener('click', toggleSignIn, false);
    document.getElementById('quickstart-sign-up').addEventListener('click', handleSignUp, false);
    document.getElementById('quickstart-verify-email').addEventListener('click', sendEmailVerification, false);
    document.getElementById('quickstart-password-reset').addEventListener('click', sendPasswordReset, false);
}

// TODO: calculate score and split by files (login, tutorial, questionnaire)

window.onload = function () {
    initApp();
};

const questionnaireQuestions = [
    {
        'question': 'DRABCD is an abbreviation for the following first aid terms:\n D999\n R999\n A999\n B999\n C999\n D999',
        'answers': [

        ],
        'correctAnswer': 0
    },
    {
        'question': 'Defibrillation is usually provided by paramedics or ambulance staff to deliver an 999 to a cardiac arrest casualty.',
        'answers': [

        ],
        'correctAnswer': 0
    },
    {
        'question': 'In an emergency call 000 or if using a digital mobile call 999.',
        'answers': [

        ],
        'correctAnswer': 0
    },
    {
        'question': 'An unconscious casualty is managed in the 999 position.',
        'answers': [

        ],
        'correctAnswer': 0
    },
    {
        'question': 'CPR compressions are delivered at the rate of 999 per minute.',
        'answers': [

        ],
        'correctAnswer': 0
    },
    {
        'question': 'During CPR the chest of an adult will be depressed approximately one 999 of the depth of the chest. ',
        'answers': [

        ],
        'correctAnswer': 0
    },
    {
        'question': '999 and 999 will help stop bleeding on a limb.',
        'answers': [

        ],
        'correctAnswer': 0
    },
    {
        'question': 'A casualty has lost a lot of blood. After stopping the bleeding a first aider should also treat the casualty for the condition of 999',
        'answers': [

        ],
        'correctAnswer': 0
    },
    {
        'question': 'A first aider would 999 the limbs of a casualty suffering shock and wrap the casualty in a 999. Then  monitor the casualty until the ambulance arrives.',
        'answers': [

        ],
        'correctAnswer': 0
    },
    {
        'question': 'RICE is the abbreviation for soft tissue injury treatment. Please fill in the missing words below.\n R999, I999, C999, E999.',
        'answers': [

        ],
        'correctAnswer': 0
    },
    {
        'question': 'What legislation governs Occupational Health and Safety in Victoria?',
        'answers': [
            'The Health Act 1901',
            'The Occupational Health and Safety Act 2004',
            'The Australian Health and Safety Codes',
            'The Victorian Hospital and Health Codes 1995'
        ],
        'correctAnswer': 0
    },
    // 10 more with random questions and answers
    {
        'question': 'The Worksafe Requirements – First Aid in the Workplace 2008 provides:',
        'answers': [
            'Instructions on how to operate a fork lift.',
            'An easy way to workplace safety.',
            'Practical guidance in first aid to employees, employers and all other people placed under obligation by the OH&S Act 2004.',
        ],
        'correctAnswer': 0
    },
    {
        'question': 'Metal filings have penetrated the surface of the eye of a casualty in your workplace. You would:',
        'answers': [
            'Call an ambulance and await medical assistance.',
            'Call an ambulance and cover both eyes with eye pads whilst awaiting medical assistance.',
            'Call the Eye and Ear Hospital for advice',
            'Use an eye wash with saline solution to clean the eye.'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'Rescue breaths are provided by first aiders when a casualty:',
        'answers': [
            'Is not conscious.',
            'Has heart pain.',
            'Is not breathing.',
            'Is in the recovery position.'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'A workmate has scalded his arm. How long should you run cold water over the affected area? What else would you do?',
        'answers': [
            'For 5 mins and then apply a wound dressing.',
            'For 10 mins or until the casualty says to stop, then call an ambulance and wait for medical help to arrive.',
            'For 15 mins and then apply a burns cream to ease the pain.',
            'For up to 20 mins or until the burns area has been cooled to normal body temperature and then apply a non-adherent dressing (medical help would already have been called for).'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'You are performing CPR on a casualty who is not breathing. You hear a rib crack or break. You should:',
        'answers': [
            'Stop performing CPR, place the casualty in the recovery position and wait until medical help arrives',
            'Stop performing CPR, reposition your hands and then continue CPR',
            'Stop performing CPR and bandage the chest of the casualty',
            'Continue CPR without stopping'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'First Aid dressings and bandages should be:',
        'answers': [
            'Sterile and only used once before disposal',
            'Made out of wool or cotton only',
            'Only returned to the first aid kit after having been resealed in a clean plastic bag',
            'Disposed of by placing in the normal rubbish bin'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'A conscious person has severe pain in the chest, shoulder and upper arm. As a first aider you would:',
        'answers': [
            'Urgently call an ambulance, rest in a comfortable position and monitor the casualty until medical assistance arrives',
            'Encourage the casualty to walk around to confirm it really is a heart problem before calling an ambulance',
            'Place an arm in an elevation sling, give panadol to ease the pain and take them to the nearest doctor for assessment',
            'Give the casualty a cup of tea and see if they feel better in 10 minutes then call an ambulance if the pain persists.'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'How would you treat a casualty with a suspected broken leg in your workplace?',
        'answers': [
            'Call an ambulance, make the person comfortable and monitor him until medical assistance arrives',
            'Help the person hobble to a clinic or doctor for treatment',
            'Make the person comfortable and check again 10 minutes later before deciding whether to call an ambulance',
            'Call an ambulance, give panadol to ease pain and splint his leg to make him comfortable while waiting for medical help'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'Cardiac Arrest is the term used to describe:',
        'answers': [
            'Severe chest pain',
            'When the heart has ceased to pump blood around the body',
            'When a person has an airway blockage',
            'When a person is clinically dead'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'When applying an ice pack to a sprain or strain you would:',
        'answers': [
            'Place the ice pack onto the skin for 20 minutes',
            'Secure icepack on injury with a compression bandage',
            'Wrap in cloth and apply to injury for 10 minutes',
            'Call an ambulance and wait for the paramedics to apply it'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'How would you manage a casualty who has swallowed petrol?',
        'answers': [
            'Give salty water to induce vomiting',
            'Warn bystanders not to smoke to avoid an explosion',
            'Call ambulance then PIC on 131126 and monitor casualty',
            'Give milk to stop vomiting'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'Treatment for a workplace bleeding injury could include:',
        'answers': [
            'Using an alcohol swab to clean the wound',
            'Removing something imbedded in the wound',
            'Applying creams or ointments to assist the healing process',
            'Using a constrictive bandage to apply a wound dressing'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'Additional first aid supplies should be provided in a workplace:',
        'answers': [
            'When there are football or soccer players in the workplace',
            'When using poisons, hazardous materials or chemicals that may increase the risk of a workplace incident',
            'So that workers can take items home for their own use',
            'When the first aid supplier advises you to buy more'
        ],
        'correctAnswer': 0
    },
    {
        'question': 'A casualty suffering injury in the workplace should be moved:',
        'answers': [
            'When the casualty is blocking an important working area',
            'When the casualty requests to be moved',
            'Only when in danger or when medical personnel advise',
            'When directed by management or supervisor'
        ],
        'correctAnswer': 0
    },
];

// first ten letters
const answerLetter = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E',
    5: 'F',
    6: 'G',
    7: 'H',
    8: 'I',
    9: 'J',
    10: 'K',
}

let finished = false

const questionnaireScreen = document.getElementById('questionnaire-screen');

const questions = Object.entries(questionnaireQuestions);

for (let i = 0; i < questions.length; i++) {

    const questionAnswerDiv = document.createElement('div');
    const question = questions[i][1];
    const questionElement = document.createElement('div');
    questionElement.classList.add('question');
    questionAnswerDiv.id = i + 1
    questionElement.textContent = (i + 1).toString() + '. ' + question.question;
    questionAnswerDiv.appendChild(questionElement);
    const answers = question.answers;
    const allAnswersDiv = document.createElement('div');

    if (i < 10) {
        const innerHTML = questionElement.innerHTML;
        // questionElement.innerHTML = questionElement.innerHTML.replaceAll('999', '<input class="textAnswer" ' + ' id="' + i + Math.random() * 1000 + '"' + ' type="text"/>').replaceAll('\n', '<br/>')
        var count = (questionElement.innerHTML.match(/999/g) || []).length;
        for (let j = 0; j < count; j++) {
            questionElement.innerHTML = questionElement.innerHTML.replace('999', '<input class="textAnswer" ' + ' id="asd' + (i * questions.length + j) + '"' + ' type="text"/>').replaceAll('\n', '<br/>')
        }
    }


    for (let j = 0; j < answers.length; j++) {
        const labelCheckbox = document.createElement('div');
        labelCheckbox.style.padding = '10px'

        const answer = answers[j];
        const answerElement = document.createElement('input');
        answerElement.setAttribute('type', 'checkbox');
        answerElement.id = i.toString() + 'answer' + j.toString();

        answerElement.classList.add('answer');
        //label
        const label = document.createElement('label');
        // label.textContent =  answerLetter[j] + '. ' + answer;
        label.setAttribute('for', answer);
        label.className = 'answer-label';
        label.appendChild(answerElement);
        labelCheckbox.appendChild(label);
        label.innerHTML = label.innerHTML + '       ' + answerLetter[j] + '. ' + answer;

        allAnswersDiv.appendChild(labelCheckbox);

    }
    questionAnswerDiv.appendChild(allAnswersDiv);
    questionnaireScreen.appendChild(questionAnswerDiv);

}

const submitButton = document.createElement('button');
submitButton.textContent = 'Submit';
submitButton.className = 'submit-button';
questionnaireScreen.appendChild(submitButton);
questionnaireScreen.style.display = 'none';

const answerMap = {
    'answers': {

    }
}

submitButton.addEventListener('click', function () {
    const answers = document.getElementsByClassName('answer');
    const textAnswers = document.getElementsByClassName('textAnswer');
    // copy textanswers to new list
    var newList = [];

    for (var i = 0; i < textAnswers.length; i++) {
        newList.push(textAnswers[i]);

    }
    for (var i = 0; i < newList.length; i++) {
        const answer = newList[i];
        const questionNumber = parseInt(answer.parentElement.parentElement.id);
        var parent = answer.parentElement;
        answer.outerHTML = answer.value;
        if (answerMap['answers'][questionNumber] === undefined) {
            answerMap['answers'][questionNumber] = {}
        }
        answerMap['answers'][questionNumber]['afterFillingOut'] = parent.innerHTML.replaceAll("<br>", "");
    }
    let correctAnswers = 0;
    for (let i = 0; i < answers.length; i++) {
        const answer = answers[i];
        if (answer.checked) {
            const questionNumber = parseInt(answer.parentElement.parentElement.parentElement.parentElement.id);
            const answerNumber = answer.id.split('answer')[1];
            if (answerMap['answers'][questionNumber] === undefined) {
                answerMap['answers'][questionNumber] = [];
            }
            answerMap['answers'][questionNumber].push(questionnaireQuestions[questionNumber - 1].answers[answerNumber]);
        }
    }
    // answerMap['questions'] = questionnaireQuestions
    const entries = Object.entries(questionnaireQuestions);
    const allQuestions = {}
    for (var i = 0; i < entries.length; i++) {
        var index = parseInt(entries[i][0]);
        allQuestions[index + 1] = entries[i][1];

    }
    answerMap['questions'] = allQuestions
    answerMap['submitDate'] = new Date().toString()


    const dateString = new Date().toString()

    var object = {}
    object[dateString] = answerMap

    const docRef = setDoc(doc(db, "users", auth.currentUser.uid), object, {
        merge: true
    }).then((value) => {
        questionnaireScreen.innerHTML = 'Congratulations, you will be notified shortly of your results.';
    })


});


const levelMap = {
    'll': {
        'text': 'The First Responder suffers SHOCK. You deep breathe.',
        'answer': 'yes',
        'mixamo': false,
        'defibrilation': false,
        'child': false,
        'timeScale': 0.5,
    },
    'aa': {
        'text': 'Checking for danger.',
        'answer': 'yes',
        'mixamo': false,
        'defibrilation': false,
        'child': false,
        'victimPosition': {
            'x': -25.90,
            'y': -1.22,
            'z': -0.5
        },
        'groupPosition': {
            'y': 0.1
        }
    },
    'qq': {
        'text': 'You and the victim are in danger. You decide to do nothing.',
        'answer': 'no',
        'mixamo': false,
        'defibrilation': false,
        'child': false,

    },
    'sc1_1': {
        'text': 'Check for danger. Bystanders are the danger. You order them back.',
        'answer': 'yes',
        'mixamo': false,
        'defibrilation': false,
        'child': false,
        'victimPosition': {
            'x': -25.71,
            'y': -1.22,
            'z': 0
        },
        'tiltBack': -0.1,
    },
    'sc1_3': {
        'text': 'Check that the area is clear of danger.',
        'answer': 'yes',
        'mixamo': false,
        'defibrilation': false,
        'child': false,
        'victimPosition': {
            'x': -30.206,
            'y': -1.22,
            'z': 7.228
        }
    },
    'firemans': {
        'text': 'If there is danger. Remove the victim from danger. Use the firemans carry technique.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': true,
        'thirdPerson': true,
        'childRotation': {
            x: Math.PI / 2,
            y: Math.PI / 2,
            z: 0
        },
        'childPosition': {
            x: -1.47,
            y: 0.92 * 2,
            z: 1.21 * 2
        },

    },
    'sc3_4': {
        'text': 'You see danger and run away. Leaving the victim there to save yourself.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
    },
    'cc': {
        'text': 'Check for a response. Do you slap the victim? ',
        'answer': 'no',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'tiltBack': 0.1,
        'femalePosition': {
            'x': 0,
            'y': 0.1,
            'z': 0.1,
        }
    },
    'dd': {
        'text': 'Check for a response. Check the pulse.',
        'answer': 'no',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'tiltBack': 0.15
    },
    'ee': {
        'text': 'Squeeze my hand.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'tiltRight': -0.9,
        'tiltBack': 0.1,
    },
    'sc1_10': {
        'text': 'Check for a response. Give pain by squeezing the shoulders. Give sound test by clapping loudly and shouting their name.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
    },
    'sc1_11': {
        'text': 'Check for a response. Give pain by squeezing hand. Give sound test by clapping loudly and shouting their name.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
    },
    'sc1_12': {
        'text': 'Check for a response. If victim is alive, ask them to squeeze your hand as hard as possible to keep them awake.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
    },
    'sc1_22': {
        'text': 'Check for a response. Put hand on chest and feel for escape of air from nose and mouth.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'thirdPerson': true,
    },
    // 'pulse': {
    //     'text': 'Check for signs of life. Using 2 fingers check for pulse on the side of the neck.',
    //     'answer': 'no',
    //     'mixamo': true,
    //     'defibrilation': false,
    //     'child': false,
    // },
    'shake': {
        'text': 'Squeeze shoulders to give a painful response',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'mouth': true,
    },
    'sc4_3': {
        'text': ' Check for signs of life on child. Pinch shoulders.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': true,
        'childPosition': {
            'x': 0,
            'z': 0.12
            ,
            'y': 0.2,
        }
    },
    'bb': {
        'text': ' Send help.',
        'answer': 'yes',
        'mixamo': false,
        'defibrilation': false,
        'child': false,
    },
    'ff': {
        'text': 'Call 000.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'tiltBack': 0.15,
        'phone': true,
    },
    'ggg': {
        'text': 'Call 000. Put on loud speaker.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'phone': true,

    },
    'sc3_9': {
        'text': 'You decide to drive the victim to the hospital instead of calling 000.',
        'answer': 'no',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
    },
    'sc1_5': {
        'text': 'Victim is concious and breathing. Keep victim in the side position now known as the recovery position. ',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
    },
    'sc1_6': {
        'text': 'Victim is concious and breathing. Keep victim in recovery position. Reassure victim that everything is ok.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
    },
    'sc1_7': {
        'text': 'Put victim in side position. Freak out and, do nothing.',
        'answer': 'no',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
    },
    'mm': {
        'text': 'Turn victim towards you in the side position.',
        'answer': 'no',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'femalePosition': {
            'x': 0.1,
            'z': 0.15,
            'y': 0.12,
        }
    },
    'jj': {
        'text': 'Tell bystanders to stand back. Turn victim to the side position.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'tiltBack': 0.15,
    },
    'sc1_13': {
        'text': 'Roll arm out and rest victims head on their shoulder.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'tiltBack': 0.2,
    },
    'sc1_15': {
        'text': 'Roll leg out to stabilize victim in the side position.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'tiltBack': 0.2,
    },
    'sc1_16': {
        'text': 'Tilt head back to open airways.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'mouth': true,
        'femalePosition': {
            'x': 0.1,
            'y': 0.1,
            'z': -0.05,
        },
        'tiltBack': 0.2,
        'mouth': true,
    },
    'sc1_18': {
        'text': 'Tilt head back and do a 2 finger sweep of the gums on the outside to clear vomit and, obstruction.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'victimPosition': {
            'x': 0,
            'y': 0.07,
            'z': -0.001
        },
        'tiltBack': 0.15,
        'femaleScale': 0.004,
        'femalePosition': {
            'x': 0.12,
            'y': 0.16,
            'z': 0.29,
        },
        'mouth': true
    },
    'sc4_2': {
        'text': 'For a child turn them to the side position.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': true,
        'childPosition': {
            'x': 0,
            'y': -2.83,
            'z': 2.85,

        },
        'childRotation': {
            x: Math.PI / 2,
            y: 0,
            z: -Math.PI / 2
        },
        'childScale': {
            'scale': 0.027 * (scaleDownFactor)
        }
    },
    'sc4_4': {
        'text': 'For a child clear airways. Use the heimlich maneuver.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': true,
        'childPosition': {
            'x': 0,
            'y': -2.83,
            'z': 2.85,

        },
        'childRotation': {
            'x': Math.PI / 2,
            'y': 0,
            'z': -Math.PI / 2
        },
        'childScale': {
            'scale': 0.027 * (scaleDownFactor)
        },
        // 'cameraRight': true
        'thirdPerson': true,
        'timeScale': 1.3
    },
    'sc4_5': {
        'text': 'For a child give 5 back upward thrusts.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': true,
        'childPosition': {
            'x': 0,
            'y': -2.83,
            'z': 2.85,

        },
        'childRotation': {
            'x': Math.PI / 2,
            'y': 0,
            'z': -Math.PI / 2
        },
        'childScale': {
            'scale': 0.027 * (scaleDownFactor)
        },
    },
    'sc1_9': {
        'text': ' There is no response from the victim. Turn victim onto their back to give breaths.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'femalePosition': {
            'x': -0.5,
            'y': 0.05,
            'z': 0,
        }

    },
    'sc1_27': {
        'text': 'Put thumb on chin. Tilt head back. Ready to give breaths.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'mouth': true,
        'femalePosition': {
            'x': -0.05,
            'y': 0.1,
            'z': 0,
        },
        'tiltBack': 0.15,

    },
    'sc1_31': {
        'text': 'Give 2 full breaths.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'cameraBack': true,
        'mouth': true,
        'femalePosition': {
            'x': 0,
            'y': -0.01,
            'z': 0.1,
        },
        'thirdPerson': true,

    },
    'hhh': {
        'text': 'Call 000. Put on loud speaker. Continue to talk to operator as you give compressions.',
        'answer': 'no',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'femalePosition': {
            'x': 0,
            'y': 0.23,
            'z': 0,
        },
    },
    'sc1_24': {
        'text': 'Keep top arm straight when giving pumps of the chest.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'cameraBack': true,
        'femalePosition': {
            'x': 0,
            'y': 0.13,
            'z': 0,
        },
    },
    'sc1_32': {
        'text': 'Send for a defribrillator. Get ready for pumps of the chest.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'femalePosition': {
            'x': 0,
            'y': 0.13,
            'z': 0,
        },
    },
    'rest': {
        'text': 'Keep going until the first responder is exhausted or victim shows signs of life.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': false,
        'femalePosition': {
            'x': 0,
            'y': 0.13,
            'z': 0,
        },
    },
    'sc4_6': {
        'text': 'On a child give 30 half compressions using only one hand.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': true,
        'timeScale': 1.5
    },
    'sc4_7': {
        'text': 'On a child cover the nose and mouth with first responders mouth. Give 2 light breaths.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': true,
        'cameraBack': true,
    },
    'sc4_9': {
        'text': 'On a child if it\'s unkown how long the child has gone without oxygen. Give chest compressions only.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': false,
        'child': true,
    },
    'rr': {
        'text': 'You place one pad high on the left chest. You place the second pad low on the right side of the abdomen',
        'answer': 'no',
        'mixamo': true,
        'defibrilation': true,
        'child': false,
        'defibPosition': {
            'x': 0.32,
            'y': 0.12,
            'z': 4.86
        },
        'victimPosition': {
            'x': 0,
            'y': 0.05,
            'z': 0

        }
    },
    'sc1_46': {
        'text': 'Remove clothing to get access to skin. Place one pad high on the right chest area. Place second pad below on the left side of the body.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': true,
        'child': false,
        'defibPosition': {
            'x': 0.32,
            'y': 0.12,
            'z': 4.86
        },
        'femalePosition': {
            'x': 0,
            'y': 0.02,
            'z': 0.2,
        },
        'victimPosition': {
            'x': 0,
            'y': 0.04,
            'z': 0

        }
    },
    'sc1_49': {
        'text': 'Press the orange button now. Orange button is pressed. An electric shock is delivered.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': true,
        'child': false,
        'defibPosition': {
            'x': 0.32,
            'y': 0.12,
            'z': 4.86
        },
        'victimPosition': {
            'x': 0,
            'y': 0.04,
            'z': 0
        },
        'tiltBack': 0.05,
        'femalePosition': {
            'x': 0,
            'y': 0.15,
            'z': 0,
        }
    },
    'sc1_50': {
        'text': 'Find centre of chest. Start CPR. Give 30 chest compressions. ',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': true,
        'child': false,
        'victimPosition': {
            'x': 0,
            'y': 0,
            'z': -0.6
        },
        'defibPosition': {
            'x': 0.32,
            'y': 0.12,
            'z': 4.31
        },

    },
    'sc1_53': {
        'text': 'Continue to give 2 full breaths.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': true,
        'child': false,
        'victimPosition': {
            'x': 0,
            'y': 0.05,
            'z': -1
        },

        'defibPosition': {
            'x': 0.32,
            'y': 0.29,
            'z': 3.9
        },
        'cameraBack': true,
        'femalePosition': {
            'x': 0,
            'y': 0.05,
            'z': 0,
        },
        'mouth': true,
        'thirdPerson': true,

    },
    'sc4_15': {
        'text': 'On a child place the pad on the right side of the chest. Place second pad on the left side of the body.',
        'answer': 'no',
        'mixamo': true,
        'defibrilation': true,
        'child': true,
        'defibPosition': {
            'x': 0.32,
            'y': -0.02,
            'z': 4.2
        },
        'victimPosition': {
            'x': 0,
            'y': 0.04,
            'z': 0

        },
        'femalePosition': {
            'x': 0,
            'y': 0.08,
            'z': 0.2,
        }
    },
    'sc4_16': {
        'text': 'On a child remove clothing. Place one pad on the centre of the chest. Place another pad on the centre of the back.',
        'answer': 'yes',
        'mixamo': true,
        'defibrilation': true,
        'child': true,
        'defibPosition': {
            'x': -0.6,
            'y': -0.79,
            'z': -4.3
        },
        'tiltBack': 0.5,
    },
}

const cprGroup = new THREE.Group()

const levelEntries = Object.entries(levelMap)

let currentGender = 'male'

var currentLevelIndex = 0
// var currentLevelIndex = Object.entries(levelMap).findIndex(([key, value]) => key == 'sc4_16') - 1
var choseGender = false
var currentLevel = levelEntries[currentLevelIndex]


let actions = []
let mixers = []

const getCurrentLevel = (currentLevelIndex) => {
    const currentLevel = levelEntries[currentLevelIndex]
    return currentLevel
}

const levelOverlayDiv = document.getElementById('level-overlay')

const letterMap = {
    'D': {
        start: 0,
        end: 6,
        name: 'D for Danger',
    },
    'R': {
        start: 7,
        end: 16,
        name: 'R for Response',

    },
    'S': {
        start: 17,
        end: 20,
        name: 'S for Send Help',

    },
    'A': {
        start: 21,
        end: 32,
        name: 'A for Airways',

    },
    'B': {
        start: 33,
        end: 35,
        name: 'B for Breaths',

    },
    'C': {
        start: 36,
        end: 42,
        name: 'C for Compressions',

    },
    'DE': {
        start: 43,
        end: 50,
        name: 'D for Defribrillation',

    },
}

const startNextLevel = () => {
    if (currentLevelIndex < levelEntries.length) {
        setTimeout(() => {
            isQuestion = true
            levelOverlayDiv.style.display = 'flex'

            currentLevel = getCurrentLevel(currentLevelIndex)

            // find letter in letterMap that has currentIndex between start and end
            const currentLetterForLevel = Object.keys(letterMap).find(key => {
                const letter = letterMap[key]
                return currentLevelIndex >= letter.start && currentLevelIndex <= letter.end
            })



            questionDiv.innerHTML = currentLevel[1].text
            levelOverlayText.innerHTML = letterMap[currentLetterForLevel].name + '. ' + '\n' + (currentLevelIndex - letterMap[currentLetterForLevel].start + 1).toString() + ' / ' + (letterMap[currentLetterForLevel].end - letterMap[currentLetterForLevel].start + 1).toString() + '.'

            herbertMixer.removeEventListener('finished', startNextLevel)
            mixamoMixer.removeEventListener('finished', startNextLevel)

            if (currentLevel[1].mixamo) {
                mixamo.visible = true
                herbert.visible = false
            } else {
                mixamo.visible = false
                herbert.visible = true
            }



            if (currentLevel[1].child) {
                child.visible = true
                victim.visible = false

                if (currentLevel[1].childRotation) {
                    child.rotation.y = currentLevel[1].childRotation.y
                    child.rotation.x = currentLevel[1].childRotation.x
                    child.rotation.z = currentLevel[1].childRotation.z
                } else {
                    child.rotation.y = 0
                    child.rotation.x = 0
                    child.rotation.z = 0
                }
                if (currentLevel[1].childPosition) {
                    child.position.x = currentLevel[1].childPosition.x
                    child.position.y = currentLevel[1].childPosition.y / 2
                    child.position.z = currentLevel[1].childPosition.z / 2
                    gui.add(child.position, 'x').min(-5).max(5).step(0.01).name('childX')
                    gui.add(child.position, 'z').min(-5).max(5).step(0.01).name('childZ')
                    gui.add(child.position, 'y').min(-5).max(5).step(0.01).name('childY')
                } else {
                    child.position.x = 0
                    child.position.y = 0
                    child.position.z = 0
                }

                if (currentLevel[1].childScale) {
                    child.scale.x = currentLevel[1].childScale.scale
                    child.scale.y = currentLevel[1].childScale.scale
                    child.scale.z = currentLevel[1].childScale.scale
                } else {
                    child.scale.set(scaleDownFactor, scaleDownFactor, scaleDownFactor)
                }
            } else {
                child.visible = false
                victim.visible = true
            }




            if (currentLevel[1].thirdPerson) {
                debugObject.firstPerson = false
                toggleFirstPerson()
                camera.lookAt(cprGroup.position)
                cprGroup.rotation.set(0, Math.PI * 2 / 3 * 1, 0)
                // cprGroup.lookAt(camera.position)
            } else {
                debugObject.firstPerson = true
                toggleFirstPerson()
                if (herbert.visible) {
                    scene.remove(cameraGroup)
                    camera.position.set(0, 0.05, 0.15)

                    eyeBoneHerbert.add(cameraGroup)


                } else if (mixamo.visible) {
                    scene.remove(cameraGroup)
                    camera.position.set(0, 0.05, 0.15)

                    eyeBoneMixamo.add(cameraGroup)
                }
            }


            actions.forEach(action => {
                action.stop()
            })
            actions = []

            const levelString = currentLevel[0]

            if (levelString == 'sc1_46' || levelString == 'sc1_49' || levelString == 'sc1_50') {
                // traverse mixamo and set to visible = false any child containing 'shirt' 
                victim.traverse((child) => {
                    if (child.isMesh) {
                        if (child.name.toLowerCase().includes('shirt') || child.name.toLowerCase().includes('safety')) {
                            child.visible = false
                        }
                    }
                }
                )
            } else {
                victim.traverse((child) => {
                    if (child.isMesh) {
                        if (child.name.toLowerCase().includes('shirt') || child.name.toLowerCase().includes('safety')) {
                            child.visible = true
                        }
                    }
                }
                )
            }

            if (levelString == 'hhh') {


                phone2.visible = true

                // phoneAction.tracks.forEach((track) => {
                //     if (track.name.toLowerCase().includes('scale')) {
                //         track.values = []
                //     }
                // })
                const theAction = mixamo.animations.find(animation => {
                    console.log(animation.name);
                    return animation.name.toString().toLowerCase().includes('phone');
                })
                for (let i = 0; i < theAction.tracks.length; i++) {
                    if (theAction.tracks[i].name.toLowerCase().includes('scale') || theAction.tracks[i].name.toLowerCase().includes('mixamo')) {
                        theAction.tracks[i].values = []
                        theAction.tracks[i].times = []
                        theAction.tracks.splice(i, 1)
                    }
                }

                console.log(theAction);

                const phoneAction = phoneMixer.clipAction(theAction)

                phoneAction.clampWhenFinished = true
                phoneAction.setEffectiveTimeScale(0.8)
                phoneAction.setEffectiveWeight(1)
                phoneAction.loop = THREE.LoopOnce
                phoneAction.play()
                phoneAction.paused = true
                phoneAction.time = 0.05
                actions.push(phoneAction)

                if (selectedFemale) {
                    phone2.geometry.translate(0, -0.03, 0.05)
                } else {
                    // phone2.geometry.center()
                }
            } else {
                phone2.visible = false
            }


            if (herbert.visible) {
                if (herbert.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(levelString)) == undefined) {

                } else {
                    action = herbertMixer.clipAction(
                        herbert.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(levelString))
                    )
                    action.clampWhenFinished = true
                    action.setEffectiveTimeScale(0.8)
                    action.setEffectiveWeight(1)
                    action.loop = THREE.LoopOnce
                    action.play()
                    action.paused = true
                    action.time = 0.05
                    actions.push(action)

                }

            } else {
                if (mixamo.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(levelString)) == undefined) {
                } else {
                    action = mixamoMixer.clipAction(
                        mixamo.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes((levelString.includes('sc1_31') || levelString.includes('sc1_53') || levelString.includes('sc4_4') || levelString.includes('sc4_16')) ? (levelString + '_prot_2') : levelString))
                    )
                    if (levelString.includes('1_46')) {
                        action = mixamoMixer.clipAction(
                            mixamo.animations.find(anim => {
                                return (anim.name.includes('001')) && anim.name.includes('1_46')
                            })
                        )
                    }
                    action.clampWhenFinished = true
                    action.setEffectiveTimeScale(selectedFemale ? (levelString.includes('fireman') ? 0.8 : 0.65) : 0.8)
                    action.setEffectiveWeight(1)
                    action.loop = THREE.LoopOnce
                    action.play()
                    action.paused = true
                    action.time = 0.05
                    actions.push(action)

                }
                if (selectedFemale && currentLevel[1].femalePosition) {
                    mixamo.position.x = currentLevel[1].femalePosition.x
                    mixamo.position.y = currentLevel[1].femalePosition.y
                    mixamo.position.z = currentLevel[1].femalePosition.z

                } else if (selectedFemale) {
                    mixamo.position.set(0, 0.05, 0)
                }
                if (selectedFemale && currentLevel[1].femaleScale) {
                    mixamo.scale.set(currentLevel[1].femaleScale, currentLevel[1].femaleScale, currentLevel[1].femaleScale)
                } else {
                    mixamo.scale.set(1 * scaleDownFactor, 1 * scaleDownFactor, 1 * scaleDownFactor)
                }

            }

            if (currentLevel[1].timeScale != undefined) {
                action.setEffectiveTimeScale(currentLevel[1].timeScale)
            }

            if (levelString == 'jj') {
                bystanderGroup.visible = true
                bystanderGroup.position.set(2.6, 0, 2.6)
                bystanderGroup.rotation.set(0, -3, 0)
            } else if (levelString == 'sc1_1') {
                bystanderGroup.visible = true

                bystanderGroup.position.set(3.8, 0, -3.8)
                bystanderGroup.rotation.set(0, -Math.PI / 3, 0)
            } else {
                bystanderGroup.visible = false
            }

            if (child.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(levelString)) == undefined) {
                action = childMixer.clipAction(
                    child.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes('4_15'))
                )
                action.clampWhenFinished = true
                action.setEffectiveTimeScale(0.8)
                action.setEffectiveWeight(1)
                action.loop = THREE.LoopOnce
                action.play()
                action.paused = true
                action.time = 0.05
                actions.push(action)
            } else {
                action = childMixer.clipAction(
                    child.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(levelString))
                )
                action.clampWhenFinished = true
                action.setEffectiveTimeScale(currentGender == 'female' ? 1.0 : 0.8)
                action.setEffectiveWeight(1)
                action.loop = THREE.LoopOnce
                action.play()
                action.paused = true
                action.time = 0.05
                actions.push(action)
            }

            if (phone) {
                if (currentLevel[1].phone) {
                    phone.visible = true
                } else {
                    phone.visible = false
                }

            }


            if (currentLevel[1].defibrilation) {
                defib.scene.visible = true
                var possibleActions = defib.animations.filter((anim) => anim.name.toLowerCase().includes(levelString))

                possibleActions.forEach((action) => {
                    let action2 = defibMixer.clipAction(action)
                    if ((levelString == 'sc1_46' || levelString == 'sc1_49') && action.name.toLowerCase().includes('red') && action.name.toLowerCase().includes('film') == false) {
                   action2 = defibMixer.clipAction(
                            defib.animations.find(anim => {
                                    return anim.name.includes('001') && anim.name.includes( levelString + '_red')
                                })
                            )
            
                    }

                    action2.clampWhenFinished = true
                    action2.setEffectiveTimeScale(0.9)
                    action2.setEffectiveWeight(1)
                    action2.loop = THREE.LoopOnce
                    action2.play()
                    action2.paused = true
                    action2.time = 0.05
                    actions.push(action2)
                }
                )

                if (levelString.includes('sc4_16')) {
                    padsGroup.rotateY(0.4)

                }
                if (currentLevel[1].defibPosition) {
                    padsGroup.position.x = currentLevel[1].defibPosition.x
                    padsGroup.position.y = currentLevel[1].defibPosition.y
                    padsGroup.position.z = currentLevel[1].defibPosition.z
                }

                if (levelString == 'sc1_46') {
                    var action = defibMixer.clipAction(
                        defib.animations.find((anim) => anim.name.includes('sc1_49_aed'))
                    )
     

                    action.clampWhenFinished = true
                    action.setEffectiveTimeScale(0.9)
                    action.setEffectiveWeight(1)
                    action.loop = THREE.LoopOnce
                    action.play()
                    action.paused = true
                    action.time = 0.05
                    actions.push(action)

                } else if (levelString == 'sc1_50' || levelString == 'sc1_53') {
                    var action = defibMixer.clipAction(
                        defib.animations.find((anim) => anim.name.includes('sc1_46_green_film'))
                    )
                    action.clampWhenFinished = true
                    action.setEffectiveTimeScale(0.9)
                    action.setEffectiveWeight(1)
                    action.loop = THREE.LoopOnce
                    action.play()
                    action.paused = true
                    action.time = 0.01
                    actions.push(action)
                }

                if (levelString == 'sc1_49') {
                    setTimeout(() => {
                        aed.userData.lastPosition = new THREE.Vector3(aed.position.x, aed.position.y, aed.position.z)
                        aed.userData.lastRotation = new THREE.Vector3(aed.rotation.x, aed.rotation.y, aed.rotation.z)
                        aed.position.set(-2.4, 0.12, 5.1)
                        aed.rotation.set(-1.57, 0, -1.17)
                    }, 500);
                } else {
                    if (aed.userData.lastPosition != undefined) {
                        aed.position.set(aed.userData.lastPosition.x, aed.userData.lastPosition.y, aed.userData.lastPosition.z)
                        aed.rotation.set(aed.userData.lastRotation.x, aed.userData.lastRotation.y, aed.userData.lastRotation.z)

                    }
                }



            } else {
                defib.scene.visible = false
            }
            if (levelString == 'sc3_4') {
                setTimeout(() => {

                    cprGroup.rotation.set(0, 0, 0)
                    mixamo.rotation.y = 2
                })
            } else if (levelString == 'sc1_9' && selectedFemale) {
                mixamo.rotation.set(0, 0.2, 0)
            } else {
                cprGroup.rotation.set(0, 0, 0)
                mixamo.rotation.y = 0
            }



            if (victim.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(levelString)) == undefined) {
                return
            }
            let action2 = victimMixer.clipAction(
                victim.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(levelString != 'sc1_53' ? levelString : 'bb'))
            )
            action2.clampWhenFinished = true
            action2.setEffectiveTimeScale(0.8)
            action2.setEffectiveWeight(1)
            action2.loop = THREE.LoopOnce
            action2.play()
            action2.paused = true
            action2.time = 0.01
            if (currentLevel[1].victimPosition) {
                victim.position.x = currentLevel[1].victimPosition.x / 2
                victim.position.y = currentLevel[1].victimPosition.y
                victim.position.z = currentLevel[1].victimPosition.z / 2
                gui.add(victim.position, 'x').min(-25).max(25).step(0.001).name('victimX')
                gui.add(victim.position, 'y').min(-25).max(25).step(0.001).name('victimY')
                gui.add(victim.position, 'z').min(-25).max(25).step(0.001).name('victimZ')
            } else {
                victim.position.x = 0
                victim.position.y = 0
                victim.position.z = 0
            }

            if (currentLevel[1].groupPosition) {
                cprGroup.position.y += currentLevel[1].groupPosition.y
            } else {
                cprGroup.position.set(0, 0, 0)
            }


            actions.push(action2)



        }, 2000)
    } else {
        showFinalQuestionnaire();
    }
}


const questionDiv = document.querySelector('#question')
const levelOverlayText = document.querySelector('#level-overlay-text')

levelOverlayText.textContent = 'D for Danger.\n1 / 7.'
questionDiv.textContent = currentLevel[1].text



/**
 * Loaders
 */

const manager = new THREE.LoadingManager()

const fbxLoader = new FBXLoader(manager)
const cubeTextureLoader = new THREE.CubeTextureLoader()

// Debug
const gui = new dat.GUI()
// gui.close()
gui.hide()

const pause = {
    pause: false
}
gui.add(pause, 'pause').name('Pause').onChange(() => {
    if (pause.pause) {
        actions.forEach(action => {
            action.paused = true
        })
        console.timeEnd('animation until now')
    } else {
        actions.forEach(action => {
            action.paused = false
        })
    }
})


// gui.hide()
const debugObject = {
    cameraRotationX: 0,
    cameraRotationY: 0,
    cameraRotationZ: 0,
}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Update all materials
 */
const updateAllMaterials = () => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            // child.material.envMap = environmentMap
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMap2/px.png',
    '/textures/environmentMap2/nx.png',
    '/textures/environmentMap2/py.png',
    '/textures/environmentMap2/ny.png',
    '/textures/environmentMap2/pz.png',
    '/textures/environmentMap2/nz.png'
])

let eyeBoneHerbert = null
let eyeBoneMixamo = null

environmentMap.encoding = THREE.sRGBEncoding

// scene.background = environmentMap
// scene.environment = environmentMap

debugObject.envMapIntensity = 0.2
updateAllMaterials()
debugObject.firstPerson = true
debugObject.dontStartLevel = false
gui.add(debugObject, 'firstPerson').onChange(() => {
    toggleFirstPerson()
})

gui.add(debugObject, 'dontStartLevel')

const toggleFirstPerson = () => {
    if (debugObject.firstPerson) {
        controls.enabled = false

        if (eyeBoneHerbert) {
            scene.remove(cameraGroup)
            camera.position.set(0, 0.05, 0.15)

            eyeBoneHerbert.add(cameraGroup)
        } else if (eyeBoneMixamo) {
            scene.remove(cameraGroup)
            camera.position.set(0, 0.05, 0.15)

            eyeBoneMixamo.add(cameraGroup)
        }

    } else {
        controls.enabled = true
        cameraGroup.removeFromParent()
        scene.add(cameraGroup)
        camera.position.set(3, 3, 3)
    }
}


const modelMap = {
    '0': {
        'url': 'models2/prot_mixamo_woman_phone5.fbx',
        'type': 'protagonist',
    },

    '3': {
        'url': 'models2/child.fbx',
        'type': 'victim',
    },
    '1': {
        'url': 'models2/victim2.fbx',
        'type': 'victim',
    },
    '2': {
        'url': 'models2/prot_herbert_woman.fbx',
        'type': 'protagonist',
    },
}

const mouthMorphs = []
const chestMorphs = []

function pumpChest() {
    chestMorphs.forEach(child => {
        const morphTargetInfluences = child.morphTargetInfluences
        if (morphTargetInfluences['1'] !== undefined) {
            // transform to gsap
            gsap.to(morphTargetInfluences, {
                '2': 1,
                duration: 0.3,
                ease: 'power2.inOut',
                onComplete: () => {
                    gsap.to(morphTargetInfluences, {
                        '2': 0,
                        duration: 0.5,
                        ease: 'power2.linear',
                    })
                }
            })
        } else {
            // morphTargetInfluences['0'] = 1
            gsap.to(morphTargetInfluences, {
                '0': 1,
                duration: 0.3,
                ease: 'power2.inOut',
                onComplete: () => {
                    gsap.to(morphTargetInfluences, {
                        '0': 0,
                        duration: 0.5,
                        ease: 'power2.linear',
                    })
                }
            })
        }
    })
}


let totalItems = 1

const loadingBar = document.getElementById("loading-bar");
const loadingPercentage = document.getElementById("loading-percentage");
const loadingScreen = document.getElementById("loading-screen");

let itemsLoaded = 0
THREE.DefaultLoadingManager.onProgress = function (url, loaded, itemsTotal) {
    itemsLoaded++;
    const progress = loaded / (itemsTotal);
    loadingBar.style.width = `${progress * 100}%`;
    loadingPercentage.textContent = `${(progress * 100).toFixed(0)}%`;
};

console.time('Finish loading')

manager.onLoad = function () {
    console.timeEnd('Finish loading')
    loadingBar.style.width = `100%`;
    setTimeout(() => {
        loadingBar.style.width = `0%`;
        loadingPercentage.textContent = `${(0 * 100).toFixed(0)}%`;

    }, 100)
    loadingScreen.style.display = "none";
}


manager.onStart = function (url, itemsLoaded, itemsTotal) {
    totalItems += itemsTotal
}


var mapsWithTextureLoader = {
    '0': {
        'eyes': 'textures/1/compressed/eyes/HG_Eye_Color.jpg',
        'eyesNormal': 'textures/1/compressed/eyes/HG_Eye_Normal.jpg',
        'pants': 'textures/1/compressed/pants/HG_Skinny_Jeans_Diffuse.jpg',
        'pantsNormal': 'textures/1/compressed/pants/HG_Skinny_Jeans_Normal.jpg',
        'pantsRoughness': 'textures/1/compressed/pants/HG_Skinny_Jeans_Roughness.jpg',
        'pantsMetallic': 'textures/1/compressed/pants/HG_Skinny_Jeans_Metallic.jpg',
        'shirt': 'textures/1/compressed/shirt/HG_TSHIRT_Material.001_BaseColor.jpg',
        'shirtNormal': 'textures/1/compressed/shirt/HG_TSHIRT_Material.001_Normal.jpg',
        'shirtRoughness': 'textures/1/compressed/shirt/HG_TSHIRT_Material.001_Roughness.jpg',
        'shoes': 'textures/1/compressed/shoes/HG_Sneaker_Suede_Diffuse.jpg',
        'shoesNormal': 'textures/1/compressed/shoes/HG_Sneaker_Suede_Normal.jpg',
        'shoesRoughness': 'textures/1/compressed/shoes/HG_Sneaker_Suede_Roughness.jpg',
        'skin': 'textures/1/compressed/skin/skin_color.jpg',
        'skinNormal': 'textures/1/compressed/skin/skin_normal.jpg',
        'skinRoughness': 'textures/1/compressed/skin/skin_roughness.jpg',
    },
    '1': {
        'eyes': 'textures/2/compressed/eyes/HG_Eye_Color.jpg',
        'eyesNormal': 'textures/2/compressed/eyes/HG_Eye_Normal.jpg',
        'pants': 'textures/2/compressed/pants/HG_Chinos_Diffuse.jpg',
        'pantsNormal': 'textures/2/compressed/pants/HG_Chinos_Normal.jpg',
        'pantsRoughness': 'textures/2/compressed/pants/HG_Chinos_Roughness.jpg',
        'collar': 'textures/2/compressed/shirt/collar/HG_Dress_Shirt_Base_Color.jpg',
        'collarNormal': 'textures/2/compressed/shirt/collar/HG_Dress_Shirt_Normal.jpg',
        'collarRoughness': 'textures/2/compressed/shirt/collar/HG_Dress_Shirt_Roughness.jpg',
        'blouse': 'textures/2/compressed/shirt/blouse/HG_SWEATER1_DIF.jpg',
        'blouseNormal': 'textures/2/compressed/shirt/blouse/HG_SWEATER1_Normal.jpg',
        'blouseRoughness': 'textures/2/compressed/shirt/blouse/HG_SWEATER1_Roughness.jpg',
        'shoes': 'textures/2/compressed/shoes/HG_Leather_Sneaker_Diffuse.jpg',
        'shoesNormal': 'textures/2/compressed/shoes/HG_Leather_Sneaker_Normal.jpg',
        'shoesRoughness': 'textures/2/compressed/shoes/HG_Leather_Sneaker_Roughness.jpg',
        'skin': 'textures/2/compressed/skin/skin_color.jpg',
        'skinNormal': 'textures/2/compressed/skin/skin_normal.jpg',
        'skinRoughness': 'textures/2/compressed/skin/skin_roughness.jpg',
    },
    '2': {
        'eyes': 'textures/3/compressed/eyes/HG_Eye_Color.jpg',
        'eyesNormal': 'textures/3/compressed/eyes/HG_Eye_Normal.jpg',
        'pants': 'textures/3/compressed/pants/HG_Chinos_Diffuse.jpg',
        'pantsNormal': 'textures/3/compressed/pants/HG_Chinos_Normal.jpg',
        'pantsRoughness': 'textures/3/compressed/pants/HG_Chinos_Roughness.jpg',
        'shirt': 'textures/3/compressed/shirt/HG_SWEATER1_DIF.jpg',
        'shirtNormal': 'textures/3/compressed/shirt/HG_SWEATER1_Normal.jpg',
        'shirtRoughness': 'textures/3/compressed/shirt/HG_SWEATER1_Roughness.jpg',
        'shoes': 'textures/3/compressed/shoes/HG_Running_Shoe_2_Diffuse.jpg',
        'shoesNormal': 'textures/3/compressed/shoes/HG_Running_Shoe_2_Normal.jpg',
        'shoesRoughness': 'textures/3/compressed/shoes/HG_Running_Shoe_2_Roughness.jpg',
        'skin': 'textures/3/compressed/skin/skin_color.jpg',
        'skinNormal': 'textures/3/compressed/skin/skin_normal.jpg',
        'skinRoughness': 'textures/3/compressed/skin/skin_roughness.jpg',
    },

}

for (var i = 0; i < Object.entries(mapsWithTextureLoader).length; i++) {
    const entry = Object.entries(mapsWithTextureLoader)[i][1]
    const key = Object.entries(mapsWithTextureLoader)[i][0]
    Object.entries(entry).forEach(([key2, value2]) => {
        mapsWithTextureLoader[key][key2] = textureLoader.load(value2)
        mapsWithTextureLoader[key][key2].repeat.set(1, 1)
        mapsWithTextureLoader[key][key2].wrapS = THREE.RepeatWrapping
        mapsWithTextureLoader[key][key2].wrapT = THREE.RepeatWrapping

    })
}

const vest = textureLoader.load('textures/SafetyVest01_low_BaseColor2.png')
const vestBump = textureLoader.load('textures/SafetyVest01_low_Height2.png')



/**
 * Models
 */
let mixamoMixer, herbertMixer, victimMixer, childMixer, phoneMixer = null

var index = 0;
let cameraGroup

let mixamo, herbert, victim, child = null

let action

const bystanderUrls = [
    'textures/bystanders/1.png',
    'textures/bystanders/2.png',
    'textures/bystanders/3.png',
]

const bystanderGroup = new THREE.Group()

for (var i = 0; i < bystanderUrls.length; i++) {
    bystanderUrls[i] = textureLoader.load(bystanderUrls[i])
    const planeGeometry = new THREE.PlaneGeometry(1.3, 3)
    const planeMaterial = new THREE.MeshBasicMaterial({
        map: bystanderUrls[i],
        transparent: true,
        opacity: 1,
        color: new THREE.Color('#202020')
    })

    const plane = new THREE.Mesh(planeGeometry, planeMaterial)
    plane.scale.set(1, 1, 1)
    plane.position.set((i + 1) * 2, 1.5, 0)
    plane.rotation.set(0, 0, 0)
    plane.rotation.y = Math.PI / 7 - (i) * Math.PI / 4

    plane.visible = true
    bystanderGroup.add(plane)

}

cprGroup.add(bystanderGroup)
bystanderGroup.position.set(3.8, 0, -3.8)
bystanderGroup.rotation.set(0, -Math.PI / 3, 0)
gui.add(bystanderGroup.position, 'x').min(-50).max(50).name('bystander x')
gui.add(bystanderGroup.position, 'y').min(-50).max(50).name('bystander y')
gui.add(bystanderGroup.position, 'z').min(-50).max(50).name('bystander z')
gui.add(bystanderGroup.rotation, 'x').min(-Math.PI).max(Math.PI).name('bystander rotation x')
gui.add(bystanderGroup.rotation, 'y').min(-Math.PI).max(Math.PI).name('bystander rotation y')
gui.add(bystanderGroup.rotation, 'z').min(-Math.PI).max(Math.PI).name('bystander rotation z')

gui.add(cprGroup.position, 'x').min(-50).max(50).name('cpr x')
gui.add(cprGroup.position, 'y').min(-50).max(50).name('cpr y')
gui.add(cprGroup.position, 'z').min(-50).max(50).name('cpr z')

const entries = Object.entries(modelMap)

let selectedFemale = false

const loadNextFBX = (i) => {
    const entry = entries[i][1]
    const url = entry.url
    fbxLoader.load(
        url,
        (object) => {
            if (currentLevel[1].mixamo && entry.url.toLowerCase().includes('mixamo') == false) {
                object.visible = false
            } else if (currentLevel[1].mixamo == false && entry.url.toLowerCase().includes('mixamo')) {
                object.visible = false
            }

            if (entry.url.toString().toLowerCase().includes('mixamo')) {
                mixamo = object

                if (entry.url.toString().toLowerCase().includes('woman')) {
                    selectedFemale = true
                    // object.position.set(0.27,0.07,0.1)
                    gui.add(mixamo.position, 'x').min(-50).max(50).name('mixamo x')
                    gui.add(mixamo.position, 'y').min(-50).max(50).name('mixamo y')
                    gui.add(mixamo.position, 'z').min(-50).max(50).name('mixamo z')

                }
            } else if (entry.url.toString().toLowerCase().includes('herbert')) {
                herbert = object
            } else if (entry.url.toString().toLowerCase().includes('victim')) {
                victim = object

            } else if (entry.url.toString().toLowerCase().includes('child')) {
                child = object
            }


            object.frustumCulled = false
            // Model
            object.scale.set(scaleDownFactor, scaleDownFactor, scaleDownFactor)
            cprGroup.add(object)
            object.position.y += 0.05
            if (object.animations.length > 0) {
                if (entry.type == 'protagonist') {
                    if (entry.url.toString().toLowerCase().includes('herbert') == false) {
                        mixamoMixer = new THREE.AnimationMixer(object)

                        action = mixamoMixer.clipAction(
                            object.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(currentLevel[0]) || animation.name.toString().toLocaleLowerCase().includes('ggg'))
                        )
                        mixers.push(mixamoMixer)
                    } else {
                        herbertMixer = new THREE.AnimationMixer(object)

                        action = herbertMixer.clipAction(
                            object.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(currentLevel[0]) || animation.name.toString().toLocaleLowerCase().includes('ll'))
                        )
                        mixers.push(herbertMixer)
                    }

                } else {
                    if (entry.url.toString().toLocaleLowerCase().includes('child') == false) {
                        victimMixer = new THREE.AnimationMixer(object)
                        action = victimMixer.clipAction(
                            object.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(currentLevel[0]) || animation.name.toString().toLocaleLowerCase().includes('ll'))
                        )
                        mixers.push(victimMixer)
                    } else {
                        childMixer = new THREE.AnimationMixer(object)
                        action = childMixer.clipAction(
                            object.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(currentLevel[0]) || animation.name.toString().toLocaleLowerCase().includes('fire'))
                        )
                        mixers.push(childMixer)
                    }


                }

                if (currentLevel[1].child == false) {
                    if (entry.url.toString().toLocaleLowerCase().includes('child')) {
                        object.visible = false
                    }
                } else {
                    if (entry.url.toString().toLocaleLowerCase().includes('child') == false) {
                        object.visible = false
                    }
                }



                action.clampWhenFinished = true
                action.setEffectiveTimeScale(0.8)
                action.setEffectiveWeight(1)
                action.loop = THREE.LoopOnce
                // action.play()
                actions.push(action)


                if (currentLevel[1].timeScale != undefined) {
                    action.setEffectiveTimeScale(currentLevel[1].timeScale)
                }


                var animOptions = {};

                object.animations = object.animations.sort((a, b) => a.name.localeCompare(b.name));
                object.animations.forEach(function (animation) {
                    animOptions[animation.name.replace('_victim', '').replace('_prot', '').replace('Armature|', '').replace('Armature.002|', '')] = animation.name.replace('_victim', '').replace('_prot', '').replace('Armature|', '').replace('Armature.002|', '');
                });
                var animControl = gui.add(window, entry.url, animOptions).listen();

                animControl.onChange(function (value) {
                    // Stop the current animation
                    action.stop();

                    // Find the selected animation in the animations array
                    let selectedAnim = object.animations.find(animation => animation.name.toString().toLocaleLowerCase().includes(value.toLowerCase()));

                    // Update the clip action with the selected animation
                    action = (entry.type == 'protagonist') ? entry.url.toString().toLowerCase().includes('herbert') == false ? mixamoMixer.clipAction(selectedAnim) : herbertMixer.clipAction(selectedAnim) : entry.url.toString().toLowerCase().includes('child') == true ? childMixer.clipAction(selectedAnim) : victimMixer.clipAction(selectedAnim);

                    actions.push(action)

                    // Play the selected animation
                    action.play();
                });

            }

            if (victim == object) {
                var animations = object.animations
                var teethAnims = animations.filter((anim) => {
                    return anim.name.toLowerCase().includes('teeth') && anim.name[0] == 'A'
                }
                )
                teethAnims.forEach((anim) => {
                    // const mixer = new THREE.AnimationMixer(object)
                    // mixers.push(mixer)
                    // const aktion = mixer.clipAction(anim)
                    // aktion.clampWhenFinished = true
                    // aktion.setEffectiveTimeScale(0.8)
                    // aktion.setEffectiveWeight(1)
                    // aktion.loop = THREE.LoopRepeat
                    // aktion.time = 0.5
                    // aktion.reset().play()
                    // actions.push(aktion)

                    // gui.add(aktion, 'time').min(0).max(10).step(0.01).listen().name(anim.name.substring(0,5))
                })
            }



            object.traverse((child) => {
                child.frustumCulled = false
                if (child.isMesh) {
                    if (child.morphTargetInfluences != undefined) {
                        if (child.morphTargetInfluences[1] != undefined) {
                            child.morphTargetInfluences[1] = 1
                            child.morphTargetInfluences[0] = 1

                        } else {
                            child.morphTargetInfluences[0] = 1

                        }
                        if (object == victim && child.name.toLowerCase().includes('safety') == false
                            && child.name.toLowerCase().includes('shirt') == false
                        ) {
                            mouthMorphs.push(child)

                        } else {
                            chestMorphs.push(child)
                        }
                    }
                    pumpChest();

                    if (child.name.toLowerCase().includes('teeth') && object == victim) {

                    }

                    if (entry.url.toLowerCase().includes('child')) {
                        var name
                        if (child.material.name.toLowerCase().includes('mat_')) {
                            if (child.material.name.includes('10') || child.material.name.includes('11') || child.material.name.includes('12')) {
                                name = child.material.name.substring(7, child.material.name.length)
                            } else {
                                name = child.material.name.substring(6, child.material.name.length)
                            }
                        } else {
                            name = child.material.name
                        }
                        child.material = new THREE.MeshStandardMaterial()
                        child.material.map = textureLoader.load('textures/child/' + name)
                        child.material.metalness = 0
                        child.material.roughness = 0.8
                    }
                    if (child.name.includes('Vest')) {
                        child.material = new THREE.MeshStandardMaterial()
                        child.material.map = vest
                        child.material.bumpMap = vestBump
                        child.material.bumpScale = 0.11
                        child.material.roughness = 1
                        child.material.metalness = 1

                    }
                    if (child.name.includes('phone') && child.name.includes('001') == false) {
                        child.material.map = textureLoader.load('textures/abc.jpg')
                        child.material.map.wrapS = THREE.RepeatWrapping
                        child.material.map.wrapT = THREE.RepeatWrapping
                        child.material.map.repeat.set(1, 1)
                        console.log(child);
                        phone = child
                        child.visible = false
                        if (Array.isArray(child.material)) {
                            child.material.forEach((material) => {
                                if (material.name == 'glossy') {
                                    material.map = phoneBackTexture
                                } else if (material.name == 'screen') {
                                    material.map = phoneScreenTexture
                                } else if (material.name == 'glass') {
                                    material.transparent = true
                                    material.opacity = 0.2
                                }

                            })
                        }

                    } else if (child.name.includes('phone') && child.name.includes('001')) {

                        phone2 = child
                        child.visible = false
                        child.material.map = textureLoader.load('textures/abc.jpg')
                        child.material.map.wrapS = THREE.RepeatWrapping
                        child.material.map.wrapT = THREE.RepeatWrapping
                        child.material.map.repeat.set(1, 1)
                        console.log(child);
                        phoneMixer = new THREE.AnimationMixer(child)
                        mixers.push(phoneMixer)

                    }

                    if (child.name.toLowerCase().includes('eyes') && entry.type == 'protagonist') {
                        if (entry.url.toString().toLowerCase().includes('mixamo') == false) {

                            eyeBoneHerbert = child.skeleton.bones.find((bone) => {
                                return bone.name.toLowerCase().includes('head')
                            });


                        } else {
                            eyeBoneMixamo = child.skeleton.bones.find((bone) => {
                                return bone.name.toLowerCase().includes('head')
                            });

                        }

                        cameraGroup = new THREE.Group()
                        cameraGroup.add(camera)
                        camera.rotateY(-Math.PI)
                        cameraGroup.position.set(0, 0, 0)



                        if (debugObject.firstPerson) {
                            if (currentLevel[1].mixamo) {
                                if (eyeBoneMixamo)
                                    eyeBoneMixamo.add(cameraGroup)
                                camera.position.set(0, 0.05, 0.15)
                            } else {
                                if (eyeBoneHerbert)
                                    eyeBoneHerbert.add(cameraGroup)
                                camera.position.set(0, 0.05, 0.15)
                            }



                        } else {
                            scene.add(cameraGroup)
                            camera.position.set(3, 3, 3)
                        }
                    }


                    if (child.name.toLowerCase().includes('eyes')) {

                        child.material = new THREE.MeshStandardMaterial({
                            map: mapsWithTextureLoader['' + i.toString()]['eyes'],
                            normalMap: mapsWithTextureLoader['' + i.toString()]['eyesNormal'],
                        })
                    }
                    // Eyes


                    // Skin - Body
                    if (child.name.toLowerCase().includes('hg_body')) {
                        child.material = new THREE.MeshStandardMaterial({
                            map: mapsWithTextureLoader['' + i.toString()]['skin'],
                            normalMap: mapsWithTextureLoader['' + i.toString()]['skinNormal'],
                            roughnessMap: mapsWithTextureLoader['' + i.toString()]['skinRoughness'],
                        })
                    }
                    // Chinos/Jeans
                    if (child.name.toLowerCase().includes('chinos') || child.name.toLowerCase().includes('jeans')) {
                        child.material = new THREE.MeshStandardMaterial({
                            map: mapsWithTextureLoader['' + i.toString()]['pants'],
                            normalMap: mapsWithTextureLoader['' + i.toString()]['pantsNormal'],
                            roughnessMap: mapsWithTextureLoader['' + i.toString()]['pantsRoughness'],
                            metalnessMap: mapsWithTextureLoader['' + i.toString()]['pantsMetallic'],
                        })
                        if (child.name.toLowerCase().includes('jeans')) {
                            child.material = new THREE.MeshStandardMaterial({
                                map: mapsWithTextureLoader['0']['pants'],
                                roughnessMap: mapsWithTextureLoader['0']['pantsRoughness'],
                                metalnessMap: mapsWithTextureLoader['0']['pantsMetallic'],
                                color: child.name.toLowerCase().includes('jeans') ? new THREE.Color('lightblue') : new THREE.Color().setHSL(Math.random(), 0.1, Math.random()),

                            })

                        }
                    }
                    if (child.name.toLowerCase().includes('lower')) {
                        child.material.map = textureLoader.load('textures/3/TeethTongueSet_C_2K.png')
                    }

                    // Sweater/TShirt
                    if (child.name.toLowerCase().includes('sweater') || child.name.toLowerCase().includes('tshirt')) {
                        const random = Math.random()
                        child.material = new THREE.MeshStandardMaterial({
                            map: mapsWithTextureLoader['' + i.toString()]['shirt'],
                            normalMap: mapsWithTextureLoader['' + i.toString()]['shirtNormal'],
                            roughnessMap: mapsWithTextureLoader['' + i.toString()]['shirtRoughness'],
                            color: new THREE.Color(random, random, random)

                        })
                    }


                    // Collar
                    if (child.name.toLowerCase().includes('collar')) {
                        child.material = new THREE.MeshStandardMaterial({
                            map: mapsWithTextureLoader['' + i.toString()]['collar'],
                            normalMap: mapsWithTextureLoader['' + i.toString()]['collarNormal'],
                            roughnessMap: mapsWithTextureLoader['' + i.toString()]['collarRoughness'],
                        })

                    }
                    // Shoe/Sneakers
                    if (child.name.toLowerCase().includes('shoe') || child.name.toLowerCase().includes('sneakers')) {
                        child.material = new THREE.MeshStandardMaterial({
                            map: mapsWithTextureLoader['' + i.toString()]['shoes'],
                            normalMap: mapsWithTextureLoader['' + i.toString()]['shoesNormal'],
                            roughnessMap: mapsWithTextureLoader['' + i.toString()]['shoesRoughness'],
                            metalnessMap: mapsWithTextureLoader['' + i.toString()]['shoesMetallic'],
                            // color dark brown
                            color: child.name.toLowerCase().includes('shoe') ? new THREE.Color('white') : new THREE.Color(0.2, 0.1, 0.0)
                        })
                    }
                    // Hair/Mesh
                    if (child.name.toLowerCase().includes('hair')) {
                        child.material = new THREE.MeshStandardMaterial({
                            roughness: 0,
                            metalness: 1,
                            color: 0x000000,
                            alphaTest: 0.5,
                            // depthWrite: false,
                        })
                    }
                    child.castShadow = true
                    child.receiveShadow = true
                    child.material.skinning = true
                } else if (child.isLight) {
                    child.visible = false

                }
            }
            )


        })
    index++;
    if (index < 2) {
        loadNextFBX(3)
    }
}

const tutorialVideos = []

const tutorialScreen = document.getElementById('tutorial-screen')

let currentVideoIndex = 0

const showNextVideo = () => {
    if (currentVideoIndex < tutorialVideos.length) {
        if (currentVideoIndex > 0) {
            tutorialVideos[currentVideoIndex - 1].style.display = 'none'
            tutorialVideos[currentVideoIndex - 1].pause()
            tutorialVideos[currentVideoIndex - 1].muted = true
        }
        tutorialVideos[currentVideoIndex].style.display = 'block'
        tutorialVideos[currentVideoIndex].addEventListener('ended', showNextVideo)
        tutorialVideos[currentVideoIndex].muted = false
        tutorialVideos[currentVideoIndex].play()
        currentVideoIndex++
    } else {
        endTutorial();
    }
}

const buttonRow = document.getElementById('button-row2')


const startShowingVideos = () => {
    buttonRow.style.display = 'flex'

    tutorialVideos.sort((a, b) => a.id - b.id)
    showNextVideo()

}

const endTutorial = () => {
    tutorialVideos[currentVideoIndex - 1].style.display = 'none'
    tutorialVideos[currentVideoIndex - 1].pause()
    tutorialScreen.style.display = 'none'
    genderOverlay.style.display = 'flex'
    startLoading()
}

const showFinalQuestionnaire = () => {
    finished = true
    questionnaireScreen.style.display = 'flex'
}

const startTutorial = () => {
    buttonRow.style.display = 'none'

    const loginScreen = document.getElementById('login-screen')
    const next = document.getElementById('next')
    const skip = document.getElementById('skip')

    next.addEventListener('click', () => {
        showNextVideo()
    })

    skip.addEventListener('click', () => {
        endTutorial()
    })


    loginScreen.style.display = 'none'
    listAll(reference).then((res) => {
        for (let i = 0; i < res.items.length; i++) {
            const itemRef = res.items[i]
            getDownloadURL(itemRef).then((url) => {
                const video = document.createElement('video')
                video.src = url
                video.autoplay = false
                video.muted = true
                video.controls = true
                video.id = itemRef.name.split('.mp4')[0]
                video.style.width = '100%'
                video.style.height = '100%'
                video.style.objectFit = 'contain'
                video.style.position = 'absolute'
                video.style.top = '0'
                video.style.zIndex = res.items.length - parseInt(video.id);
                video.style.left = '0'
                video.style.display = 'none'
                tutorialVideos.push(video)
                tutorialScreen.appendChild(video)

                if (tutorialVideos.length === res.items.length) {
                    startShowingVideos();
                } else {
                }

            })
        }

    })
}

const startLoading = () => {


    if (currentLevelIndex != 0) {
        genderOverlay.style.display = 'none'
        chooseGender('female')
    }

    loadingScreen.style.display = 'flex'
    loadNextFBX(1)


    fbxLoader.load('models2/office/3.fbx', (object) => {
        // object.frustumCulled = false
        // Model
        object.scale.set(0.02, 0.02, 0.02)
        object.traverse((child) => {
            // child.position.y += Math.random() / 10
            if (child.isLight) {
                // child.visible = false
            } else {
                if (child.isMesh) {
                    if (child.name.includes('door')
                        || child.name.includes('Line')) {
                        child.material = new THREE.MeshBasicMaterial({
                            color: 0x000000,
                            transparent: true,
                            opacity: 0.95,
                        })
                    }

                }

                child.frustumCulled = true

                child.castShadow = true
                child.receiveShadow = true
            }
        })

        scene.add(object)
    })
}




scene.add(cprGroup)

var random = Math.random()
if (random < 0.3) {
    cprGroup.rotateY(Math.PI * 2 / 3)
} else if (random < 0.6) {
    cprGroup.rotateY(Math.PI * 2 / 3 * 2)
} else {
    cprGroup.rotateY(Math.PI * 2 / 3 * 3)
}
cprGroup.scale.set(0.8, 0.8, 0.8)
cprGroup.position.set(-5, 0, 3.5)

let isQuestion = true

const answerQuestion = (e) => {

    console.timeEnd('animation until now')
    const answer = e.target.innerHTML
    const correctAnswer = currentLevel[1].answer.toUpperCase()
    if (answer === correctAnswer) {
        currentLevelIndex++
    } else {
        currentLevelIndex = 0
    }
    // Start blur
    const levelBackground = document.getElementById('level-background')
    levelBackground.style.backdropFilter = 'blur(0px)'
    levelOverlayDiv.style.opacity = 0
    setTimeout(() => {
        isQuestion = false

        levelOverlayDiv.style.display = 'none'
        levelOverlayDiv.style.opacity = 1
        levelBackground.style.backdropFilter = 'blur(4px)'
        if (debugObject.dontStartLevel == false) {
            if (herbert.visible) {
                herbertMixer.addEventListener('finished', startNextLevel)
            } else {
                if (currentLevel[0] == 'sc4_5') {
                    actions.forEach((action) => {
                        action.time = 1
                    })
                    let count = 0
                        mixamoMixer.addEventListener('finished', () => {
                            count++
                    if (count < 5) {

                        setTimeout(() => {
                            actions.forEach((action) => {
                                action.time = 1
                                action.paused = false
                            })
                        }, 200)
                        } else {
                           startNextLevel()
    
                        }
                        });
               

                } else {
                    mixamoMixer.addEventListener('finished', startNextLevel)

                }
            }
        }

        actions.forEach((action) => {
            action.paused = false
            action.play()
        })
        console.time('animation until now')
        if (currentLevel[0] == 'sc1_24') {
            setTimeout(() => {
                pumpChest();
            }, 300)
        } if (currentLevel[0] == 'hhh') {
            setTimeout(() => {
                pumpChest();
                setTimeout(() => {
                    phone2.geometry.translate(0, 0.03, -0.05)


                }, 2500)

            }, 1400)

        }
        if (currentLevel[0] == 'rest') {
            pumpChest();
            setTimeout(() => {
                pumpChest();
            }, 600)
        }

        if (actions.length == 0 || actions.length == 1) {
            startNextLevel()
        }
    }, 1000)


}

const yesButton = document.getElementById('yes')
const noButton = document.getElementById('no')


yesButton.addEventListener('click', answerQuestion);
noButton.addEventListener('click', answerQuestion);

const femaleButton = document.getElementById('female')
const maleButton = document.getElementById('male')

maleButton.addEventListener('click', (e) => {
    chooseGender('male')
})

femaleButton.addEventListener('click', (e) => {
    chooseGender('female')
})
var functionObject = {
    startNextLevel: startNextLevel,
    pumpChest: pumpChest,
}

gui.add(functionObject, 'startNextLevel').name('Start Next Level')
gui.add(functionObject, 'pumpChest').name('Pump Chest')
const genderOverlay = document.getElementById('gender-overlay')


const chooseGender = (gender) => {
    choseGender = true
    currentGender = gender
    genderOverlay.style.display = 'none'
    levelOverlayDiv.style.display = 'flex'

    if (gender == 'male') {
        modelMap['0']['url'] = 'models2/prot_mixamo_phone5.fbx'
        modelMap['2']['url'] = 'models2/prot_herbert2.fbx'
        loadNextFBX(0)
        loadNextFBX(2)
    } else if (gender == 'female') {
        modelMap['0']['url'] = 'models2/prot_mixamo_woman_phone5.fbx'
        modelMap['2']['url'] = 'models2/prot_herbert_woman.fbx'
        loadNextFBX(0)
        loadNextFBX(2)
    }

    loadingScreen.style.display = "flex";

}




let defibMixer, defib = null
let phone, phone2 = null

const phoneBackTexture = new THREE.TextureLoader().load('textures/phone/back.jpg')
const phoneScreenTexture = new THREE.TextureLoader().load('textures/phone/screen.jpg')
const possibleMatches = ['rr', 'sc1_46', 'sc1_49', 'sc1_50', 'sc1_53', 'sc4_16', 'sc4_15']

const defibMixers = []
const padsGroup = new THREE.Group()

let pack
var gltfLoader = new GLTFLoader()

let aed = null
gltfLoader.load('models2/defibrillator2.glb', (object) => {
    defib = object
    object.frustumCulled = false

    if (currentLevel[1].defibrilation) { } else {
        defib.scene.visible = false
    }

    const defibScale = 0.5
    defib.scene.scale.set(defibScale, defibScale, defibScale)

    defibMixer = new THREE.AnimationMixer(object.scene)
    mixers.push(defibMixer)

    gui.add(window, 'currentMatch', possibleMatches).name('Match').onChange((value) => {


        var possibleClips = object.animations.filter((animation) => {
            return animation.name.includes(value)
        })

        actions.forEach((action) => {
            action.reset().stop()
        })
        actions = []

        possibleClips.forEach((clip) => {
            if (clip.name.toLowerCase().includes('pack') == false) {
                const action = defibMixer.clipAction(clip)
                action.clampWhenFinished = true
                action.loop = THREE.LoopOnce
                action.reset().play()
                actions.push(action)
            }

        })

        // TODO: or avea animatiile si alte boneuri gresite gen rr_aed sa aiba bones pt film

        // const aedMixer = new THREE.AnimationMixer(object.scene)
        // // just like for aed, make mixers for 'red', 'red film', 'green', 'green film', 'pack'
        // const redMixer = new THREE.AnimationMixer(object.scene)
        // const redFilmMixer = new THREE.AnimationMixer(object.scene)
        // const greenMixer = new THREE.AnimationMixer(object.scene)
        // const greenFilmMixer = new THREE.AnimationMixer(object.scene)
        // const packMixer = new THREE.AnimationMixer(object.scene)


    })


    const padsArray = []

    defib.scene.traverse((child) => {
        child.frustumCulled = false

        if (child.name.includes('green_film') || child.name.includes('red_film')) {
            if (child.parent.type.toLowerCase().includes('one') == false) {
                padsArray.push(child)
            }
        } else if (child.name.includes('pack')) {
            pack = child
        }
        if (child.isMesh) {
            if (child.material) {
                child.material.side = THREE.DoubleSide
            }
        }

        if (child.name.includes('AED002')) {
            aed = child

        }
    })

    padsArray.forEach((pad) => {
        padsGroup.add(pad)
    })

    defib.scene.add(padsGroup)

    cprGroup.add(object.scene)


})


/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 0.8)
directionalLight.castShadow = true
directionalLight.shadow.camera.far = 250
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.normalBias = 0.005
directionalLight.position.set(3.5, 12, - 1.25)
scene.add(directionalLight)

// hemisphere with specific colors

const hemiLight = new THREE.HemisphereLight(0x0f253d, 0x080820, 0.5)
scene.add(hemiLight)

// gui.add(hemiLight, 'intensity').min(0).max(10).step(0.001).name('lightIntensity')
// gui.add(hemiLight.position, 'x').min(- 5).max(5).step(0.001).name('lightX')
// gui.add(hemiLight.position, 'y').min(- 5).max(5).step(0.001).name('lightY')
// gui.add(hemiLight.position, 'z').min(- 5).max(5).step(0.001).name('lightZ')

// directional with specific color

// const directionalLight = new THREE.DirectionalLight('#ffffff', 1)
// directionalLight.castShadow = true
// directionalLight.shadow.camera.far = 100
// directionalLight.shadow.mapSize.set(1024, 1024)
// directionalLight.shadow.normalBias = 0.001
// directionalLight.position.set(3.5, 12, - 1.25)
// scene.add(directionalLight)

// gui.add(directionalLight, 'intensity').min(0).max(10).step(0.001).name('lightIntensity')
// gui.add(directionalLight.position, 'x').min(- 5).max(5).step(0.001).name('lightX')
// gui.add(directionalLight.position, 'y').min(- 5).max(5).step(0.001).name('lightY')
// gui.add(directionalLight.position, 'z').min(- 5).max(5).step(0.001).name('lightZ')

const ambientLight = new THREE.AmbientLight('#b9d5ff', 0.4)
scene.add(ambientLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(80, sizes.width / sizes.height, 0.001, 100)
camera.position.y = 10
gui.add(camera, 'fov').min(0).max(180).step(0.001).name('cameraFov').onChange(() => {
    camera.updateProjectionMatrix()
})

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enabled = !debugObject.firstPerson

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    powerPreference: 'high-performance',
    logarithmicDepthBuffer: true
})
renderer.useLegacyLights = false
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.toneMappingExposure = 1.75
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setClearColor('#202020')
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

let speed = 0
let pointer = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2
}

let playerX, playerY

let playerQuaternion = new THREE.Quaternion()

window.addEventListener('mousemove', (e) => {
    if (isQuestion) return

    let x = e.clientX / sizes.width - 0.5
    let y = 1 - e.clientY / sizes.height - 0.5

    pointer.x = x
    pointer.y = y

    // calculate quaternion based on pointer position
    playerQuaternion = new THREE.Quaternion().setFromUnitVectors(
        new THREE.Vector3(0, 0, 1),
        new THREE.Vector3(x / 6, y / 6, 0.5).normalize()
    )


})

window.addEventListener('touchmove', (e) => {
    e.preventDefault()
    let x = e.touches[0].clientX / sizes.width - 0.5
    let y = 1 - e.touches[0].clientY / sizes.height - 0.5

    pointer.x = x
    pointer.y = y

    // calculate quaternion based on pointer position
    playerQuaternion = new THREE.Quaternion().setFromUnitVectors(
        new THREE.Vector3(0, 0, 1),
        new THREE.Vector3(x / 3, y / 3, 0.5).normalize()
    )

})

const tick = () => {
    if (Math.abs(speed) > 0.001) {
        speed *= 0.9
    }

    if (phone2) {
        phone2.scale.set(450, 450, 450)

    }

    if (currentLevel[1].mouth) {
        mouthMorphs.forEach(morph => {
            morph.morphTargetInfluences[0] = 1;
            if (morph.morphTargetInfluences[1] != undefined) {
                morph.morphTargetInfluences[1] = 1;
            }
        })
    } else {
        mouthMorphs.forEach(morph => {
            morph.morphTargetInfluences[0] = 0;
            if (morph.morphTargetInfluences[1] != undefined) {
                morph.morphTargetInfluences[1] = 0;
            }
        })
    }


    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update controls
    controls.update()
    if (camera && eyeBoneHerbert && debugObject.firstPerson) {
        // make camera look towards the head
        camera.quaternion.set(
            eyeBoneHerbert.quaternion.x,
            eyeBoneHerbert.quaternion.y,
            eyeBoneHerbert.quaternion.z,
            eyeBoneHerbert.quaternion.w
        )
        const lookAtHeadQuaternion = new THREE.Quaternion()
        lookAtHeadQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, currentLevel[1].tiltBack != null && currentLevel[1].tiltBack ? currentLevel[1].tiltBack : 0.3), Math.PI)

        // 1. Create a new quaternion representing the rotation around the up axis
        const yAxis = new THREE.Vector3(0, 1, 0); // Y-axis (up)
        const angleInRadians = currentLevel[1].tiltRight ? currentLevel[1].tiltRight : 0;
        const rotationQuaternion = new THREE.Quaternion().setFromAxisAngle(yAxis, angleInRadians);

        // Inside the loop
        // ---------------
        // 2. Clone the original playerQuaternion
        const playerQuaternionClone = playerQuaternion.clone();

        // 3. Multiply the playerQuaternionClone by the rotation quaternion
        playerQuaternionClone.multiply(rotationQuaternion);

        // 4. Now you can multiply the camera quaternion with the updated playerQuaternionClone
        camera.quaternion.multiply(lookAtHeadQuaternion)

        camera.quaternion.multiply(playerQuaternionClone);


        // make it look the opposite way
        // camera.quaternion.multiply(playerQuaternion)



        if (currentLevel[1].cameraRight) {
            camera.position.x = -0.05
            camera.position.y = 0.15
        } else if (currentLevel[1].cameraBack) {
            camera.position.x = -0.0
            camera.position.y = 0.05

            camera.position.z = 0.15 - 0.02
            // camera.position.y = 0.15
        } else {
            camera.position.x = 0.0
            camera.position.y = 0.0
        }
        camera.position.x += speed
    }

    // Fox animation
    // if (mixamoMixer) mixamoMixer.update(deltaTime)
    // if (herbertMixer) herbertMixer.update(deltaTime)
    // if (victimMixer) victimMixer.update(deltaTime)
    // if (childMixer) childMixer.update(deltaTime)
    // if (defibMixer) defibMixer.update(deltaTime)

    mixers.forEach((mixer) => {
        mixer.update(deltaTime)
    })


    if (pack && !pause.pause) {
        padsGroup.position.set(
            pack.position.x,
            padsGroup.position.y,
            padsGroup.position.z
        )
        if (currentLevel[1].defibPosition) {
            padsGroup.position.x = pack.position.x + currentLevel[1].defibPosition.x
            padsGroup.position.y = currentLevel[1].defibPosition.y
            // padsGroup.position.z = currentLevel[1].defibPosition.z
        }
    }
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    if (!finished) {
        window.requestAnimationFrame(tick)

    }
}

tick()